<template>
  <div>
    <div class>
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw">
          <div class="titles">我收藏的商品</div>
          <div class="content">
            <div class="box" v-for="(v, i) in favoriteList" :key="i">
              <div class="top">
                <img @click="GoGood(v)" :src="v.thumb" alt />
                <div class="fr">
                  <p class="p1 two red">
                    {{ v.title ? v.title : '暂无显示' }}
                  </p>
                  <p class="p2 one">￥{{v.price}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部

import { usergetinfo } from "@/request/user";
import { getFavoriteList } from "@/request/index";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      favoriteList: [],
      expertList: []
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    this.shop_id = localStorage.getItem("lzyyShopId");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.start();
  },
  methods: {
    start() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
      //   收藏的商品
      getFavoriteList({
        token: this.$token, 
        shop_id: this.shop_id
      }).then(res => {
		  console.log(res.data.data)
        this.favoriteList = res.data.data;
      })

    },
    GoGood(v){
      this.$router.push({name:"商品详情",query:{id:v.goods_id,shopId:v.shop_id}});
     }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 400px;
  height: 240px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-top: none;
  padding-left: 42px;
  padding-top: 46px;
  img {
    width: 115px;
    height: 115px;
    cursor: pointer;
  }
  .fr {
    width: 200px;
    margin-right: 20px;
    padding-top: 10px;
  }
  .p1 {
    font-size: 18px;
    height: 50px;
    span {
      font-size: 16px;
      color: #4eeec8;
    }
  }
  .p2 {
    margin-top: 24px;
    font-size: 16px;
    color: #ff0000;
  }
  .bottom {
    margin-top: 24px;
    padding-right: 56px;
    display: flex;
    justify-content: space-around;
    p {
      width: 130px;
      height: 40px;
      line-height: 40px;
      background: rgba(78, 238, 200, 1);
      color: white;
      text-align: center;
    }
    p:nth-of-type(2) {
      background: white;
      color: rgba(78, 238, 200, 1);
      border: 1px solid rgba(78, 238, 200, 1);
    }
  }
}
.content {
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.titles {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  text-indent: 36px;
  border-bottom: 1px solid #e6e6e6;
}
</style>