/**
 * @Author: zhang
 * @Date:   2021-01-25 13:12:03
 * @Last Modified by:   zhang
 * @Last Modified time: 2021-04-08 16:09:22
 */
<template>
  <div>
    <div class="public-bottom">
      <div class="wrap">
        <div class="boxs">
          <p>
            {{ sysconfs.site_icp }}
          </p>
          <p>Copyright © 2020-2030 Fonow Medicine · All rights reserved</p>
          <p>
            <span>
              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2020039710号-1 </a>
			  · 
			  <a href="https://fu-nuo.com/internetcert2.jpg" target="_blank">互联网药品信息服务资格证书编号：(浙)-经营性-2020-0191</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSysconfs } from "@/request/public";

export default {
  data() {
    return {
      sysconfs: {},
    };
  },
  created() {
    // 获取网站基本信息
    getSysconfs({ token: this.$token }).then((res) => {
      if (res.code == 1) {
        this.sysconfs = res.data;
      }
    });
  },
};
</script>
<style lang="less" >
@import "../../assets/less/publicBottom";
</style>