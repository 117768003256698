import { get , post } from './http'

// 公共 获取验证码   
export const sendSms = data => post('api/public/sendSms', data)

// 获取系统设置  空
export const getSysconfs = data => get('/api/public/getSysconf', data)

//  获取药店列表
export const get_shop_list = data => post('api/public/getShoplist', data)

// 文件上传
export const upload = data => get('api/public/upload', data)

// 搜索门店
export const search_shop_list = data => post("api/shop/index", data)
